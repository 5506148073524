module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-150373456-1"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
