if (typeof window !== "undefined") {
  window.onload = function() {
    initHeader()
    initNav()

    document
      .querySelectorAll(".defered-bg")
      .forEach(item => item.classList.remove("defered-bg"))
  }
}

// Autohide header
// ✅ hide when scroll
// ❎ bg alpha = 0 on top
function initHeader() {
  let prevScrollpos = window.pageYOffset
  window.onscroll = function() {
    const maxScroll = document.body.clientHeight - window.innerHeight
    let currentScrollPos = window.pageYOffset
    if (
      (maxScroll > 0 &&
        prevScrollpos > currentScrollPos &&
        prevScrollpos <= maxScroll) ||
      (maxScroll <= 0 && prevScrollpos > currentScrollPos) ||
      (prevScrollpos <= 0 && currentScrollPos <= 0)
    ) {
      document.querySelector("#navbar").style.top = "0"

      if (currentScrollPos <= 600) {
        document.querySelector("#gotoTop").classList.add("fade-out")
      }
    } else {
      document.querySelector("#navbar").style.top = "-5.0rem"
      document.querySelector("#gotoTop").classList.remove("fade-out")
    }
    prevScrollpos = currentScrollPos
  }
}

// Scroll behavior
function initNav() {
  console.log("initNav:", Array.from(document.querySelectorAll(".gw")))
  Array.from(document.querySelectorAll(".gw")).forEach(link => {
    console.log("registered link event")
    link.addEventListener("click", e => {
      console.log("scrolled to " + link.getAttribute("href"))
    })
  })
}
